<template>
  <login-layout>
    <header class="login-pf-header">
      <h1>{{ _t('Per poter accedere è necessario accettare i nostri "Termini e condizioni"') }}</h1>
    </header>

    <x-form v-if="contractState.state === 'success'" @submit.prevent="accept">
      <iframe class="terms" referrerpolicy="no-referrer" sandbox="" :srcdoc="contract.testo" />
      <div>
        <input id="accept" type="checkbox" name="accept" required> <label for="accept">{{ _t('Ho letto e accettato i termini e le condizioni') }}</label>
      </div>

      <a class="btn-logout pull-right" href="/api/auth/logout">{{ $t('Logout') }}</a>
    </x-form>

    <div v-else class="blank-slate-pf">
      <div class="spinner spinner-lg blank-slate-pf-icon" />
      <h3 class="blank-slate-pf-main-action">
        {{ _t('Caricamento termini e condizioni') }}
      </h3>
    </div>
  </login-layout>
</template>

<style scoped>
.terms {
  width: 100%;
  max-width: 800px;
  border: 1px solid #c0c0c0;
  height: 300px;
  overflow: scroll;
  margin-bottom: 10px;
}
.btn-logout {
  margin-top: 40px;
}
</style>

<script lang="ts" setup>
import LoginLayout from '../../components/login-layout.vue';
import { ContractResource, CompanyResource } from '@/resources';
import { asyncComputed } from '@common/asyncComputed';
import { useLoginStore } from '../../store/login';
import { useAppStore } from '@/store/app';
import XForm from '../../components/x-form.vue';
import { $t as _t } from '@/i18n';
import { loginDestination } from '../../utils';
import { useRouter, useRoute } from 'vue-router';
import { onBeforeMount, watch } from 'vue';
import { AsyncComputedState } from '@common/asyncComputed';

const login = useLoginStore();
const router = useRouter();
const route = useRoute();

onBeforeMount(() => {
  if (login.contratto_cliente !== false) {
    router.push(loginDestination(route.query));
  }
});

const { result: contract, state: contractState } = asyncComputed(async() => {
  const contract = await new ContractResource().get('cliente');
  contract.testo = `<html><head><style>
body {
  zoom: 80%;
  margin: 10px;
}

p:first-child {
  margin-top: 0;
}

p:last-child {
  margin-bottom: 0;
}
</style></head>
<body>${contract.testo}</body></html>`;
  return contract;
});

async function accept() {
  if (login.id_azienda) {
    await new CompanyResource().put(login.id_azienda, {
      contratto_cliente: true,
    });

    const app = useAppStore();
    app.toast(_t('Termini e condizioni accettati'), 'success');
    await login.refresh();
  }

  router.push(loginDestination(route.query));
}

watch(() => contractState.state, (value) => {
  document.body.dataset.ouiaPageSafe = value === AsyncComputedState.Updating ? 'false' : 'true';
});
</script>
