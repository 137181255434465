<template>
  <div v-if="surveyState.state !== 'success'" class="blank-slate-pf">
    <div class="spinner spinner-lg blank-slate-pf-icon" />
    <h3 class="blank-slate-pf-main-action">
      {{ $t('Stiamo caricando le domande e le tue risposte precedenti') }}
    </h3>
  </div>
  <form v-else @submit.prevent="submit">
    <p>{{ _t('Benvenuti nel nostro questionario annuale obbligatorio per i partner!') }}</p>
    <p>{{ _t('Questo strumento è pensato per farci comprendere al meglio le vostre necessità e aspettative, nonché per assicurarci che i dati che ci fornite siano sempre aggiornati.') }}</p>
    <p>{{ _t('Raccogliendo informazioni dettagliate sulle vostre competenze e caratteristiche, possiamo indirizzare in modo più accurato i lavori verso i partner più adatti, migliorando l\'efficienza e la soddisfazione di tutti.') }}</p>
    <p>{{ _t('Vi chiederemo di dedicare un momento per compilare questo questionario una volta all\'anno, garantendo così l\'attualità e la precisione delle informazioni condivise. La vostra partecipazione è essenziale per rafforzare la nostra collaborazione e guidarci verso un futuro di successo congiunto.') }}</p>
    <p>{{ _t('Grazie per il vostro impegno e la vostra collaborazione.') }}</p>
    <div v-for="(param, question) of questions" :key="question" class="form-group">
      <label for="question">{{ param.name }}<template v-if="param.required"> *</template></label>
      <select
        id="question"
        v-model="survey[question]"
        name="question"
        class="form-control"
        :required="param.required"
      >
        <option v-for="option in param.options" :key="option">
          {{ option }}
        </option>
      </select>
    </div>
    <div class="form-group">
      <label for="reseller_allow_newsletter">
        {{ _t("Autorizzo l'invio di newsletter ai clienti") }}
        <form-help-tooltip text="Con questa opzione ci autorizzi ad inviare newsletter informative e commerciali ai tuoi clienti" />
      </label>
      <toggle
        v-model="survey.reseller_allow_newsletter"
        name="reseller_allow_newsletter"
        :on-value="true"
        :off-value="false"
      />
    </div>
    <div class="form-group">
      <label for="question">
        {{ _t("Autorizzo il contatto commerciale con i clienti") }}
        <form-help-tooltip text="Con questa opzione ci autorizzi a contattare direttamente i tuoi clienti per proporre nuovi acquisti e upgrade" />
      </label>
      <toggle
        v-model="survey.reseller_allow_contact"
        name="reseller_allow_contact"
        :on-value="true"
        :off-value="false"
      />
    </div>
    <div class="form-group">
      <label for="question">{{ _t("Cosa ti piace dell'area clienti?") }}</label>
      <textarea
        v-model="survey.admin_pros"
        name="admin_pros"
        class="form-control"
        required
      />
    </div>
    <div class="form-group">
      <label for="question">{{ _t("Cosa non ti piace dell'area clienti?") }}</label>
      <textarea
        v-model="survey.admin_cons"
        name="admin_cons"
        class="form-control"
        required
      />
    </div>
    <div class="form-group">
      <label for="question">{{ _t("Quali nuove funzionalità vorresti vedere implementate in quest'area clienti?") }}</label>
      <textarea
        v-model="survey.admin_feature_request"
        name="admin_feature_request"
        class="form-control"
        required
      />
    </div>

    <pf-button type="submit" :disabled="sending">{{ _t('Invia') }}</pf-button>
  </form>
</template>

<style scoped>
label {
  display: blocK;
}
.form-group{
  max-width: 500px;
}
textarea {
  width: 100%;
  height: 100px;
}
</style>

<script lang="ts" setup>
import { ref } from 'vue';
import { $t as _t } from '@/i18n';
import { CRM_ANNIATTIVITA, CRM_ASSOCIAZIONI, CRM_CATEGORIAPROSPECT, CRM_CLIENTI, CRM_DIMENSIONECLIENTI, CRM_DIPENDENTI, CRM_FATTURATO, CRM_PREFERENZAFATTURAZIONE } from '../common/consts';
import { asyncComputed } from '@common/asyncComputed';
import { SurveyResource } from '@/resources';
import Toggle from '../components/toggle.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';

const emit = defineEmits<{
  (e: 'submit'): void
}>();

const sending = ref();

const {result: survey, state: surveyState} = asyncComputed(async() => {
  return await new SurveyResource().get(1);
});

const questions = {
  'categoria_prospect': {
    name: _t('Categoria'),
    options: CRM_CATEGORIAPROSPECT,
    required: true,
  },
  'dipendenti': {
    name: _t('Numero dipendenti'),
    options: CRM_DIPENDENTI,
    required: true,
  },
  'anni_attivita': {
    name: _t('Anni di attività'),
    options: CRM_ANNIATTIVITA,
    required: true,
  },
  'fatturato': {
    name: _t('Fatturato attuale'),
    options: CRM_FATTURATO,
    required: true,
  },
  'clienti': {
    name: _t('Clienti attuali'),
    options: CRM_CLIENTI,
    required: true,
  },
  'dimensione_clienti': {
    name: _t('Clienti preferiti'),
    options: CRM_DIMENSIONECLIENTI,
    required: true,
  },
  'associazioni': {
    name: _t('Assiociazioni di cui fate parte'),
    options: CRM_ASSOCIAZIONI,
    required: false,
  },
  'preferenza_fatturazione': {
    name: _t('Preferenza di fatturazione'),
    options: CRM_PREFERENZAFATTURAZIONE,
    required: true,
  },
};

async function submit() {
  if (sending.value) {
    return;
  }

  sending.value = true;

  try {
    await new SurveyResource().save(survey.value);
    emit('submit');
  } finally {
    sending.value = false;
  }
};
</script>
