import { createRouter, createWebHistory, type RouteMeta, type RouteParamsRaw, type RouteRecordRaw } from 'vue-router';
import axios from 'axios';
import * as Sentry from '@sentry/vue';

import { loginDestination } from './utils';

import LoginPage from './pages/auth/login.vue';
import LoginAziendaPage from './pages/auth/login-azienda.vue';
import TermsAndConditions from './pages/auth/terms-and-conditions.vue';
import ApprovalRequest from  './pages/auth/approval_request.vue';
import AcceptInvitePage from './pages/auth/accept-invite.vue';
import AuthorizeUserPage from './pages/auth/authorize-user.vue';
import SignupPage from './pages/auth/signup.vue';
import ResetpasswordPage from './pages/auth/resetpassword.vue';
import WebmailPage from './pages/webmail.vue';
import AccountMovementsPage from './pages/account-movements.vue';
import DashboardPage from './pages/dashboard.vue';
import ProfilePage from './pages/profile.vue';
import CompanyPage from './pages/company.vue';
import InvoicesPage from './pages/invoices.vue';
import MyCustomersPage from './pages/my-customers.vue';
import MySkillsPage from './pages/my-skills.vue';
import OpportunitiesPage from './pages/opportunities.vue';
import PaymentPage from './pages/payment.vue';
import CatalogPage from './pages/catalog.vue';
import CatalogOrderPage from './pages/catalog-order.vue';
import OrderResultPage from './pages/order-result.vue';
import ProductRenewPage from './pages/product-renew.vue';
import ProductUpgradePage from './pages/product-upgrade.vue';
import ProductsPage from './pages/products.vue';
import OffersPage from './pages/offers.vue';
import ConfirmOfferPage from './pages/confirm-offer.vue';
import SecurityPage from './pages/security.vue';
import ServicesPage from './pages/services.vue';
import ServersPage from  './pages/servers.vue';
import TicketPage from './pages/ticket.vue';
import TicketsPage from './pages/tickets.vue';
import UsersPage from './pages/users.vue';
import BecomePartnerPage from './pages/become-partner.vue';
import DocumentsPage from './pages/documents.vue';
import UnsubscribePage from './pages/auth/unsubscribe.vue';
import AxervePaypalErrorPage from './pages/axerve-paypal-error.vue';
import NotFoundPage from './pages/not-found.vue';
import { useAppStore } from './store/app';
import { useLoginStore, type LoginData } from './store/login';
import { useCompanyStore, type CompanyData } from './store/company';
import WizardPage from './components/wizard/wizard-page.vue';

const routes: RouteRecordRaw[] = [{
  name: 'login',
  path: '/auth/login',
  component: LoginPage,
  meta: {
    fullPageWithBg: true,
    requiresAuth: false,
    onlyNotAuth: true,
  },
}, {
  name: 'login_azienda',
  path: '/auth/login_azienda',
  component: LoginAziendaPage,
  meta: {
    fullPageWithBg: true,
    requiresAuth: true,
    preEnter: true,
  },
}, {
  name: 'terms_and_conditions',
  path: '/auth/terms_and_conditions',
  component: TermsAndConditions,
  meta: {
    fullPageWithBg: true,
    requiresAuth: true,
    preEnter: true,
  },
}, {
  name: 'approval_request',
  path: '/auth/approval_request',
  component: ApprovalRequest,
  meta: {
    fullPageWithBg: true,
    requiresAuth: true,
    preEnter: true,
  },
}, {
  name: 'accept_invite',
  path: '/auth/accept-invite/:antk',
  component: AcceptInvitePage,
  meta: {
    fullPageWithBg: true,
    requiresAuth: true,
    preEnter: true,
  },
}, {
  name: 'authorize_user',
  path: '/auth/authorize-user/:aid;:uid;:tok',
  component: AuthorizeUserPage,
  props: true,
  meta: {
    fullPageWithBg: true,
    requiresAuth: true,
    preEnter: true,
  },
}, {
  name: 'signup',
  path: '/auth/signup',
  component: SignupPage,
  meta: {
    fullPageWithBg: true,
    requiresAuth: false,
    onlyNotAuth: true,
  },
}, {
  name: 'resetpassword',
  path: '/auth/resetpassword',
  component: ResetpasswordPage,
  meta: {
    fullPageWithBg: true,
    requiresAuth: false,
    onlyNotAuth: true,
  },
}, {
  name: 'unsubscribe',
  path: '/unsubscribe/:mail',
  component: UnsubscribePage,
  meta: {
    fullPageWithBg: true,
    requiresAuth: false,
  },
}, {
  name: 'webmail',
  path: '/webmail',
  component: WebmailPage,
  meta: {
    fullPageWithBg: true,
    requiresAuth: false,
  },
}, {
  name: 'dashboard',
  path: '/:id_cliente(\\d+)?',
  component: DashboardPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.developer || login.grants.sales,
  },
}, {
  name: 'account_movements',
  path: '/account_movements',
  component: AccountMovementsPage,
  meta: {
    requiresAuth: true,
    visible: (customer: CompanyData, login: LoginData) => login.is_reseller,
    grant: (customer: CompanyData, login: LoginData) => login.is_reseller && login.grants.billing,
  },
}, {
  name: 'invoices',
  path: '/invoices',
  component: InvoicesPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.email_subscriptions.includes('billing'),
  },
}, {
  name: 'payment',
  path: '/payments/:id',
  component: PaymentPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing,
  },
}, {
  name: 'catalog',
  path: '/:id_cliente(\\d+)?/catalog',
  component: CatalogPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.developer || login.grants.sales,
  },
}, {
  name: 'diy-offer',
  path: '/diy-offer/',
  component: WizardPage,
  props: {
    freePeriod: 0,
  },
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.developer || login.grants.sales,
  },
}, {
  name: 'product-testing',
  path: '/product-testing/',
  component: WizardPage,
  props: {
    freePeriod: 60,
  },
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.developer || login.grants.sales,
  },
}, {
  name: 'catalog_order',
  path: '/:id_cliente(\\d+)?/catalog/:id',
  component: CatalogOrderPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.enable_orders,
  },
}, {
  name: 'order_result',
  path: '/:id_cliente(\\d+)?/order-result/:id',
  component: OrderResultPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.enable_orders,
  },
}, {
  name: 'products',
  path: '/:id_cliente(\\d+)?/products',
  component: ProductsPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.developer || login.grants.sales,
  },
}, {
  name: 'offers',
  path: '/:id_cliente(\\d+)?/offers',
  component: OffersPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.developer || login.grants.sales,
  },
}, {
  name: 'confirm_offer',
  path: '/:id_cliente(\\d+)?/offers/:id',
  component: ConfirmOfferPage,
  props: true,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.enable_orders,
  },
}, {
  name: 'product_renew',
  path: '/products/:id/renew',
  component: ProductRenewPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.sales,
  },
}, {
  name: 'product_upgrade',
  path: '/products/:id/upgrade',
  component: ProductUpgradePage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.sales,
  },
}, {
  name: 'security',
  path: '/security',
  component: SecurityPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.developer || login.grants.sales,
  },
}, {
  name: 'my_customers',
  path: '/my_customers',
  component: MyCustomersPage,
  meta: {
    requiresAuth: true,
    visible: (customer: CompanyData, login: LoginData) => login.is_reseller && (login.grants.billing || login.grants.developer || login.grants.sales),
  },
}, {
  name: 'my_skills',
  path: '/my_skills',
  component: MySkillsPage,
  meta: {
    requiresAuth: true,
    visible: (customer: CompanyData, login: LoginData) => login.is_reseller,
    grant: (customer: CompanyData, login: LoginData) => login.is_reseller && login.grants.billing,
  },
}, {
  name: 'opportunities',
  path: '/opportunities',
  component: OpportunitiesPage,
  meta: {
    requiresAuth: true,
    visible: (customer: CompanyData, login: LoginData) => login.is_reseller,
    grant: (customer: CompanyData, login: LoginData) => login.is_reseller && (login.grants.billing || login.grants.developer || login.grants.sales),
  },
}, {
  name: 'services',
  path: '/:id_cliente(\\d+)?/services',
  component: ServicesPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.developer || login.grants.sales,
  },
}, {
  name: 'servers',
  path: '/:id_cliente(\\d+)?/servers',
  component: ServersPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.developer || login.grants.sales,
  },
}, {
  name: 'tickets',
  path: '/tickets',
  component: TicketsPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.developer || login.grants.sales,
  },
}, {
  name: 'ticket',
  path: '/tickets/:id',
  component: TicketPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.developer || login.grants.sales,
  },
}, {
  name: 'company',
  path: '/:id_cliente(\\d+)?/company',
  component: CompanyPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.admin || login.grants.billing,
  },
}, {
  name: 'profile',
  path: '/profile',
  component: ProfilePage,
  meta: {
    requiresAuth: true,
  },
}, {
  name: 'users',
  path: '/users',
  component: UsersPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.admin,
  },
}, {
  name: 'documents',
  path: '/documents',
  component: DocumentsPage,
  meta: {
    requiresAuth: true,
    grant: (customer: CompanyData, login: LoginData) => login.grants.billing || login.grants.developer || login.grants.sales,
  },
}, {
  name: 'become_partner',
  path: '/become-partner',
  component: BecomePartnerPage,
  meta: {
    requiresAuth: true,
    visible: (customer: CompanyData, login: LoginData) => !login.is_reseller && !login.has_parent && login.grants.billing,
  },
}, {
  name: 'axerve_paypal_error',
  path: '/axerve_paypal_error',
  component: AxervePaypalErrorPage,
  meta: {
    requiresAuth: true,
    visible: (customer: CompanyData, login: LoginData) => login.enable_orders || login.grants.billing || login.grants.sales,
  },
}, {
  name: 'not-found',
  path: '/:pathMatch(.*)*',
  component: NotFoundPage,
  meta: {
    fullPageWithBg: true,
    requiresAuth: false,
  },
}];

// VueRouter lancia un'eccezione se c'è più di un redirect per push. Rompono solo le scatole.
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location, onResolve, onReject) {
//   if (onResolve || onReject) {
//     return originalPush.call(this, location, onResolve, onReject);
//   }
//   return originalPush.call(this, location).catch(err => {
//     if (VueRouter.isNavigationFailure(err)) {
//       // resolve err
//       return err;
//     }
//     // rethrow error
//     return Promise.reject(err);
//   });
// };

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active',
});

router.beforeEach(async(to, from, next) => {
  const forceDestination = (name: string, withRef?: boolean, params?: RouteParamsRaw) => {
    if (to.name !== name) {
      next({
        name,
        query: withRef && to.fullPath !== '/' ? { ref: to.fullPath } : undefined,
        params,
      });
    } else {
      next();
    }
  };

  const app = useAppStore();
  const login = useLoginStore();
  const company = useCompanyStore();

  if (!login.id || !login.id_azienda) {
    try {
      await login.refresh();
    } catch (ex: any) {
      if (!ex.response || !axios.isAxiosError(ex) || ![401, 404].includes(ex.response.status)) {
        Sentry.captureException(ex);
        return next();
      }
    }
  }

  const matchEveryDestination = (fn: (meta: RouteMeta) => boolean) => {
    return to.matched.every(r => fn(r.meta));
  };

  const matchSomeDestination = (fn: (meta: RouteMeta) => boolean) => {
    return to.matched.some(r => fn(r.meta));
  };

  const checkGrants = () => matchEveryDestination(meta => typeof meta.grant !== 'function' || meta.grant(company, login));
  const checkVisible = () => matchEveryDestination(meta => typeof meta.visible !== 'function' || meta.visible(company, login));

  if (app.maintenance) {
    return next();
  }

  if (matchSomeDestination(meta => meta.requiresAuth ?? false)) {
    if (!login.id) {
      return forceDestination('login', true);
    }

    if (!matchSomeDestination(meta => meta.preEnter ?? false)) {
      if (!login.id_azienda) {
        return forceDestination('login_azienda', true);
      }

      if (login.contratto_cliente === false) {
        return forceDestination('terms_and_conditions', true);
      }

      if (login.approval !== 'approvato') {
        return forceDestination('approval_request', true);
      }

      if (!checkGrants() || !checkVisible()) {
        return forceDestination('profile');
      }
    }
  } else if (matchSomeDestination(meta => meta.onlyNotAuth ?? false) && login.id && !app.isInIframe) {
    return next(loginDestination(to.query));
  }

  next();
});

router.afterEach(to => {
  if (typeof to.name === 'string') {
    document.body.dataset.ouiaPageType = to.name;
    if (typeof to.params.id === 'string') {
      document.body.dataset.ouiaPageObjectId = to.params.id;
    }
  } else {
    delete document.body.dataset.ouiaPageType;
  }
});

export default router;
