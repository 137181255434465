<template>
  <h4>{{ $t('Dati del registrante del dominio {0}', [domain.dominio]) }}</h4>
  <form role="form" method="post" @submit.prevent="send">
    <input type="hidden" name="id" :value="domain.id">

    <div class="row">
      <div class="form-group col-lg-3 col-sm-6">
        <label for="domainAuthcode">
          {{ $t('Codice authcode') }}
          <form-help-tooltip
            :text="$t('Si tratta del codice che occorre per poter trasferire il dominio, se non lo hai devi richiederlo al tuo attuale provider che è obbligato a inviartelo.')"
          />
        </label>
        <div>
          <input
            id="domainAuthcode"
            type="text"
            name="authcode"
            class="form-control"
          >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-lg-3 col-sm-6">
        <label for="domainName"> {{ $t('Nome') }} * </label>
        <div>
          <input
            id="domainName"
            type="text"
            name="nome"
            class="form-control"
            required
          >
        </div>
      </div>
      <div class="form-group col-lg-3 col-sm-6">
        <label for="domainSurname"> {{ $t('Cognome') }} * </label>
        <div>
          <input
            id="domainSurname"
            type="text"
            name="cognome"
            class="form-control"
            required
          >
        </div>
      </div>
    </div>

    <div v-if="login.complete" class="billingToggle">
      {{ $t('Utilizza i dati di fatturazione come dati del registrante') }}:
      <input v-model="usaDatiFatturazione" name="usa_dati_fatturazione" type="checkbox" value="1">
    </div>

    <div v-if="asyncComputed.company.state === 'updating'" class="blank-slate-pf">
      <div class="spinner spinner-lg blank-slate-pf-icon" />
      <h3 class="blank-slate-pf-main-action">
        {{ $t('Caricamento dati di fatturazione...') }}
      </h3>
    </div>
    <fieldset v-else :disabled="usaDatiFatturazione === true">
      <div class="domainDataTipologia">
        <btn-radio v-model="domainData.tipologia" checked-value="Private" name="tipologia">
          {{ $t('Persona fisica') }}
        </btn-radio>
        <btn-radio v-model="domainData.tipologia" checked-value="Company" name="tipologia">
          {{ $t('Azienda') }}
        </btn-radio>
      </div>

      <div v-if="!personaFisica" class="row">
        <div class="form-group col-md-12 col-lg-6">
          <label for="domainDataRagioneSociale">{{ $t('Ragione Sociale') }} *</label>
          <input
            id="domainDataRagioneSociale"
            v-model="domainData.ragione_sociale"
            name="ragione_sociale"
            type="text"
            class="form-control"
            required
            maxlength="250"
          >
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-8 col-lg-4">
          <label for="domainDataNazione">{{ $t('Nazione') }} *</label>
          <select
            id="domainDataNazione"
            v-model="domainData.stato"
            name="stato"
            class="form-control"
            autocomplete="country"
          >
            <option v-for="country in countries" :key="country.iso" :value="country.iso">
              {{ country.country }}
            </option>
          </select>
        </div>

        <div class="form-group col-md-4 col-lg-2">
          <label for="domainDataCAP">{{ $t('CAP') }} *</label>
          <input
            id="domainDataCAP"
            v-model="domainData.cap"
            name="cap"
            type="text"
            class="form-control"
            required
            maxlength="10"
            autocomplete="postal-code"
          >
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-5 col-lg-4">
          <label v-if="personaFisica" for="companyIndirizzo">{{ $t('Indirizzo residenza') }} *</label>
          <label v-if="!personaFisica" for="companyIndirizzo">{{ $t('Indirizzo sede') }} *</label>
          <input
            id="domainDataIndirizzo"
            v-model="domainData.indirizzo"
            name="indirizzo"
            type="text"
            class="form-control"
            required
            maxlength="250"
            autocomplete="street-address"
          >
        </div>

        <div class="form-group col-md-5 col-lg-2">
          <label for="domainDataCitta">{{ $t('Città') }} *</label>

          <input
            id="domainDataCitta"
            v-model="domainData.citta"
            name="citta"
            type="text"
            class="form-control"
            required
            maxlength="250"
          >
        </div>

        <div class="form-group col-md-2">
          <label v-if="domainData.stato != 'CH'" for="domainDataProvincia">{{ $t('Provincia') }} *</label>
          <label v-if="domainData.stato == 'CH'" for="domainDataProvincia">{{ $t('Cantone') }} *</label>
          <input
            id="domainDataProvincia"
            v-model="domainData.provincia"
            name="provincia"
            type="text"
            class="form-control"
            required
            maxlength="2"
          >
        </div>
      </div>

      <div class="row">
        <div v-if="!personaFisica" class="form-group col-md-5 col-lg-4">
          <label for="domainDataPiva">
            {{ $t('Partita IVA') }} <span v-if="piCfRequired">*</span>
          </label>
          <input
            id="domainDataPiva"
            v-model="domainData.partita_iva"
            name="partita_iva"
            type="text"
            :required="piCfRequired"
            class="form-control"
            maxlength="16"
          >
        </div>

        <div v-else class="form-group col-md-5 col-lg-4">
          <label for="domainDataCF">
            {{ $t('Codice fiscale') }} <span v-if="piCfRequired">*</span>
          </label>
          <input
            id="domainDataCF"
            v-model="domainData.codice_fiscale"
            name="codice_fiscale"
            type="text"
            :required="piCfRequired"
            class="form-control"
            maxlength="16"
          >
        </div>
      </div>
    </fieldset>
    <button type="submit" class="btn btn-primary" :disabled="sending">{{ $t('Invia') }}</button>
    &nbsp;
    <em>* {{ $t('Campi obbligatori') }}</em>
  </form>
</template>

<style>
.billingToggle,
.domainDataTipologia {
  margin-bottom: 15px;
}
</style>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import { Resource, type Company } from '@/resources';
import { useLoginStore } from '@/store/login';
import { setupAsyncComputed } from '@common/asyncComputed';
import { useCompany } from '../utils';

import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import BtnRadio from '@/components/btn-radio.vue';
import countries from '../countries.json';

type RegistrantData = Pick<Company,
  'tipologia' |
  'ragione_sociale' |
  'stato' |
  'cap' |
  'indirizzo' |
  'citta' |
  'provincia' |
  'partita_iva' |
  'codice_fiscale'
>;

export default defineComponent({
  name: 'FormDomainData',

  components: {
    BtnRadio,
    FormHelpTooltip,
  },

  props: {
    domain: {
      type: Object as PropType<{
        id: number,
        dominio: string,
        codice: string,
      }>,
      required: true,
    },
    idAzienda: {
      type: Number,
      required: true,
    },
  },

  emits: ['submit'],

  setup() {
    const login = useLoginStore();

    return {
      login,
      ...setupAsyncComputed({
        company: useCompany(),
      }),
    };
  },

  data() {
    return {
      sending: false,
      countries,
      usaDatiFatturazione: true,
      domainData: {
        tipologia: 'Company',
        ragione_sociale: '',
        stato: '',
        cap: '',
        indirizzo: '',
        citta: '',
        provincia: '',
        partita_iva: '',
        codice_fiscale: '',
      } as RegistrantData,
    };
  },

  computed: {
    personaFisica() {
      return this.domainData.tipologia == 'Private';
    },

    piCfRequired() {
      return this.domain.codice === 'REGIT' || this.domainData.stato == 'IT';
    },
  },

  watch: {
    usaDatiFatturazione() {
      if (this.usaDatiFatturazione) {
        this.domainData = {...this.company};
      }
    },

    company() {
      this.domainData = {...this.company};
    },
  },

  methods: {
    async send(e: Event) {
      if (!(e.target instanceof HTMLFormElement)) {
        return;
      }

      this.sending = true;

      try {
        const data = new FormData(e.target);
        await new Resource('domain_registrant').post(data);

        this.$emit('submit');
      } finally {
        this.sending = false;
      }
    },
  },
});
</script>
