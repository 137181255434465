<template>
  <page
    v-model:filter="q"
    :name="$t('My cloud')"
    :empty="servers.length <= 0"
    :state="asyncComputed"
    with-filter
  >
    <pf-list-view v-slot="{ row }" :rows="(filtered as any[])" :items-per-page="0" key-name="nome" stacked>
      <server-detail :server="row" />
    </pf-list-view>
  </page>
</template>

<script lang="ts">
import { defineComponent} from 'vue';
import { ServerResource, type Server } from '@/resources';
import { setupAsyncComputed } from '@common/asyncComputed';
import { any } from '@common/utils';

import Page from '../components/page.vue';
import ServerDetail from '../components/server-detail.vue';

export default defineComponent({
  name: 'ServerPage',

  components: {
    Page,
    ServerDetail,
  },

  setup() {
    return {
      ...setupAsyncComputed({
        servers:  {
          get() {
            return new ServerResource().get();
          },
          default: [] as Server[],
        },
      }),
    };
  },

  data() {
    return {
      q: '', // search query
    };
  },

  computed: {
    filtered() {
      return this.servers.filter(any(this.q));
    },
  },
});
</script>
