<template>
  <page :name="$t('Offerta fai da te')" :description="$t('Costruisci la tua offerta personalizzata')">
    <wizard
      :free-period="freePeriod"
      :searched-domain="domainName"
      :available-domain="domainAvailable"
    />
  </page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Page from '../../components/page.vue';
import Wizard from '../../components/wizard/wizard.vue';
import { pop } from '@common/utils';
import { $t } from '@/i18n';

export default defineComponent({
  name: 'WizardPage',

  components: {
    Page,
    Wizard,
  },

  props: {
    freePeriod: {
      type: Number,
      required: true,
    },
  },

  computed: {
    domainAvailable(){
      return this.$route.query.domainAvailable === "true" || this.$route.query.domainAvailable === "1";
    },

    domainName() {
      return pop(this.$route.query.domainName);
    },
  },
});
</script>
