<template>
  <page
    :name="$t('Opportunità')"
    :description="$t('Qui trovi la lista di tutte le possibili opportunità e leads che ti abbiamo segnalato')"
    :state="asyncComputed"
  >
    <pf-list-view v-if="opportunities.length" :rows="(opportunities as any)" :items-per-page="0" key-name="id" stacked>
      <template #default="{ row }">
        <pf-list-item>
          <template #left>
            <span class="fa list-view-pf-icon-md">
              <thumb-tack-icon />
            </span>
          </template>

          <template #heading> {{ row.titolo }} </template>

          <template #description>
            {{ $t('del {0}', [$date(row.data)]) }}
          </template>

          <template #additional-info>
            <pf-list-item-additional-info stacked>
              <span>{{ $t('Cliente') }}</span>
              <strong>{{ row.cliente }}</strong>
            </pf-list-item-additional-info>
          </template>
        </pf-list-item>
      </template>
    </pf-list-view>

    <div v-else class="blank-slate-pf">
      <p>{{ $t('Al momento non ci sono opportunità o lead.') }}</p>
      <p>{{ $t('Se non l\'hai ancora fatto compila le tue skill e abilità nella sezione "Skill" e aiutaci a inviarti segnalazioni e possibili nuovi clienti') }}</p>
      <div class="blank-slate-pf-main-action">
        <router-link :to="{ name: 'my_skills' }" class="btn btn-primary btn-lg">{{ $t('Vai alle Skill') }}</router-link>
      </div>
    </div>
  </page>
</template>

<style scoped>
:deep(.list-view-pf-additional-info-item) {
  align-items: flex-start;
}
</style>

<script lang="ts">
import { setupAsyncComputed } from '@common/asyncComputed';
import { defineComponent } from 'vue';
import { OpportunityResource, type Opportunity } from '@/resources';
import Page from '../components/page.vue';
import ThumbTackIcon from '@vue-patternfly/icons/thumb-tack-icon';


export default defineComponent({
  name: 'OpportunitiesPage',

  components: {
    Page,
    ThumbTackIcon,
  },

  setup() {
    return {
      ...setupAsyncComputed({
        opportunities: {
          get() {
            return new OpportunityResource().get();
          },
          default: [] as Opportunity[],
        },
      }),
    };
  },
});
</script>
