<template>
  <page :name="product.codice || $t('Ordina')" :title="$t('Ordina Prodotto')">
    <template #breadcrumbs>
      <router-link v-slot="{ navigate }" custom :to="{ name: 'catalog' }">
        <li>
          <a @click="navigate">{{ $t('Listino') }}</a>
        </li>
      </router-link>
    </template>

    <payment-report
      :result="reportQueryData.result"
      :error="reportQueryData.error"
      route="catalog"
      :success="$t('Ordine confermato')"
      :link="$t('Torna al listino')"
    >
      <h4>{{ product.nome }}</h4>

      <div v-if="askBilling" class="checkout-step">
        <h2>
          {{ $t('Dati di fatturazione') }} <span v-if="login.complete"><ok-icon /></span>
        </h2>
        <billing-form v-if="!login.complete" embed-for="order" modal @submit="asyncComputed.asyncProduct.update" />
      </div>

      <div v-if="!productReady" class="blank-slate-pf">
        <div class="spinner spinner-lg blank-slate-pf-icon" />
        <h3 class="blank-slate-pf-main-action">
          {{ $t('Stiamo caricando i dati del prodotto') }}
        </h3>
      </div>
      <x-form
        v-else
        method="post"
        action="/api/pay_new_product"
        :prevent-submit="!canOrder"
        :disabled="!product || !product.prezzo_scontato || product.prezzo_scontato <= 0"
        :submit-help="submitHelp"
        :submit-text="submitText"
        @before-submit="saveToLocalStorage"
      >
        <div class="checkout-step">
          <h2>{{ $t('Nome a dominio') }}</h2>
          <h4>{{ product.nome }}</h4>
          <input type="hidden" name="codice" :value="product.codice">

          <template v-if="product.richiesto_dominio">
            <label class="control-label">{{ $t('Dominio') }}</label>
            <input-domain v-model="dominio" :tld="product.tld" name="dominio" :placeholder="$t('Dominio')" required />
          </template>
        </div>

        <div v-if="product.codice">
          <div style="max-width: 800px">
            <h3>{{ $t('Riepilogo:') }}</h3>
            <ul>
              <li class="row">
                <span class="col-xs-12 col-sm-4" :class="{striked: product.prezzo_scontato < product.prezzo}">{{ $t('Totale (IVA Esclusa)') }}:</span>
                <span class="col-xs-12 col-sm-2 value-cell" :class="{striked: product.prezzo_scontato < product.prezzo}">{{ $currency(product.prezzo, login.currency) }}</span>
              </li>
              <li v-if="product.prezzo_scontato < product.prezzo" class="row">
                <span class="col-xs-12 col-sm-4">{{ $t('Totale a te riservato (IVA Esclusa)') }}:</span>
                <span class="col-xs-12 col-sm-2 value-cell">{{ $currency(product.prezzo_scontato, login.currency) }}</span>
              </li>
              <li class="row">
                <span class="col-xs-12 col-sm-4"><strong>{{ $t('Totale (IVA Inclusa)') }}:</strong></span>
                <span class="col-xs-12 col-sm-2 value-cell"><strong>{{ $currency(product.prezzo_ivato, login.currency) }}</strong></span>
              </li>
            </ul>
          </div>
        </div>

        <div class="checkout-step">
          <payment-methods
            v-model="method"
            enable-cashback
            :totale-offerta-ivato="product.prezzo_ivato"
            :currency="login.currency"
          />
        </div>
      </x-form>
    </payment-report>
  </page>
</template>

<style scoped>
.checkout-step {
  margin-bottom: 30px;
}

.checkout-step h2 {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.value-cell {
  text-align: right;
}
</style>

<script lang="ts">
import Page from '../components/page.vue';
import BillingForm from '../components/billing-form.vue';
import PaymentReport from '../components/payment-report.vue';
import PaymentMethods, { type Methods} from '../components/payment-methods.vue';
import XForm from '../components/x-form.vue';
import InputDomain from '../components/input-domain.vue';
import { setupAsyncComputed } from '@common/asyncComputed';
import { useLoginStore } from '@/store/login';
import { usePaypalStore } from '@/store/paypal';
import { type GenericResource, Resource } from '@/resources';
import { defineComponent } from 'vue';
import { pop } from '@common/utils';
import { $t } from '@/i18n';
import OkIcon from '@vue-patternfly/icons/ok-icon';

export default defineComponent({
  name: 'CatalogOrderPage',

  components: {
    Page,
    BillingForm,
    PaymentReport,
    PaymentMethods,
    XForm,
    InputDomain,
    OkIcon,
  },

  setup() {
    const login = useLoginStore();
    return {
      login,
      ...setupAsyncComputed({
        asyncProduct: {
          get(this: any) {
            return new Resource('catalog').get(this.$route.params.id);
          },
          default: {} as GenericResource,
        },
      }),
    };
  },

  data() {
    return {
      method: undefined as Methods | undefined,
      dominio: '',
      askBilling: false,
    };
  },

  computed: {
    product() {
      const item = { ...this.asyncProduct };
      item.prezzo = this.login.currency == 'EUR' ? item.prezzo_eur : item.prezzo_chf;
      item.prezzo_scontato = this.login.currency == 'EUR' ? item.prezzo_scontato_eur : item.prezzo_scontato_chf;
      item.prezzo_ivato = this.login.currency == 'EUR' ? item.prezzo_ivato_eur : item.prezzo_ivato_chf;
      return item;
    },

    tax() {
      if (!this.product.prezzo_ivato) {
        return 0;
      }
      return this.product.prezzo_ivato - this.product.prezzo_scontato;
    },

    canOrder() {
      return this.login.complete;
    },

    submitText() {
      return this.method === 'custom' ? $t('Conferma ordine') : $t('Procedi al pagamento');
    },

    submitHelp() {
      return this.method && this.method != 'custom'
        ? $t("Verrai reindirizzato alla pagina di pagamento. L'operazione può durare qualche secondo.")
        : '';
    },

    productReady() {
      return this.asyncComputed.asyncProduct.state === 'success';
    },

    reportQueryData() {
      const result = pop(this.$route.query.res);
      return {
        result: result ? Number(result) : undefined,
        error: pop(this.$route.query.err) ?? undefined,
      };
    },
  },

  created() {
    this.askBilling = !this.login.complete;
  },

  methods: {
    saveToLocalStorage() {
      if (this.method === 'axerve-paypal') {
        const paypal = usePaypalStore();
        paypal.save();
      }
    },
  },
});
</script>
