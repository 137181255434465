import { http } from '@/http';
import { defineStore } from 'pinia';
import { parseDateTime } from "@common/date";
import { DateTime } from 'luxon';

export interface LoginData {
  azienda: string,
  lingua: 'it' | 'en' | 'fr' | 'de',
  sconto: number,
  iva: number,
  username: string | null,
  email_subscriptions: string[],
  grants: {
    owner: boolean,
    admin: boolean,
    billing: boolean,
    developer: boolean,
    sales: boolean,
  },
  cod_pagamento: string,
  pagamento: string,
  fatturazione_mensile: boolean,
  currency: 'EUR' | 'CHF',
  currency_locked: boolean,
  is_reseller: boolean,
  use_credit: boolean,
  verificato: boolean,
  approval: 'in approvazione cliente' | 'in approvazione rivenditore' | 'approvato' | null,
  has_active_products: boolean,
  enable_orders: boolean,
  fatturante: {
    id: number | string,
    strid: string | null,
    ragione_sociale: string,
    indirizzo: string | null,
    citta: string | null,
    provincia: string | null,
    cap: string | null,
    stato: string,
    telefono: string | null,
    piva: string | null,
    coordinate_bancarie: {
      intestatario: string,
      banca_eur: string | null,
      banca_chf: string | null,
    },
  },
  contratto_cliente: boolean | null,
  is_premium: boolean,
  has_recurring_payments: boolean,
  has_parent: boolean,
  id: number | string | null,
  id_azienda: number | string | null,
  complete: boolean,
  complete_skill: boolean,
  survey_completion_date: string | null,
}

export const useLoginStore = defineStore('login', {
  state: (): LoginData => (
    {
      azienda: '',
      lingua: 'it',
      sconto: 0,
      iva: 0,
      username: null,
      email_subscriptions: [],
      grants: {
        owner: false,
        admin: false,
        billing: false,
        developer: false,
        sales: false,
      },
      cod_pagamento: '',
      pagamento: '',
      fatturazione_mensile: false,
      currency: 'EUR',
      currency_locked: true,
      is_reseller: false,
      use_credit: false,
      verificato: false,
      approval: null,
      has_active_products: false,
      enable_orders: false,
      fatturante: {
        id: '',
        strid: null,
        ragione_sociale: '',
        indirizzo: null,
        citta: null,
        provincia: null,
        cap: null,
        stato: 'IT',
        telefono: null,
        piva: null,
        coordinate_bancarie: {
          intestatario: '',
          banca_eur: null,
          banca_chf: null,
        },
      },
      contratto_cliente: false,
      is_premium: false,
      has_recurring_payments: false,
      has_parent: false,
      id: null,
      id_azienda: null,
      complete: false,
      complete_skill: false,
      survey_completion_date: null,
    }),

  actions: {
    async refresh() {
      try {
        const { grants, ...data } = (await http.get('/api/dashboard/userinfo')).data;
        const grantsList: string[] = Array.isArray(grants) ? grants : [];

        this.azienda = data.azienda;
        this.lingua = data.lingua;
        this.sconto = data.sconto;
        this.iva = data.iva;
        this.username = data.username;
        this.email_subscriptions = data.email_subscriptions;
        this.cod_pagamento = data.cod_pagamento;
        this.pagamento = data.pagamento;
        this.fatturazione_mensile = data.fatturazione_mensile;
        this.currency = data.currency;
        this.currency_locked = data.currency_locked;
        this.is_reseller = data.is_reseller;
        this.use_credit = data.use_credit;
        this.verificato = data.verificato;
        this.approval = data.approval;
        this.has_active_products = data.has_active_products;
        this.fatturante = data.fatturante;
        this.contratto_cliente = data.contratto_cliente;
        this.is_premium = data.is_premium;
        this.has_recurring_payments = data.has_recurring_payments;
        this.has_parent = data.has_parent;
        this.id = data.id;
        this.id_azienda = data.id_azienda;
        this.complete = data.complete;
        this.complete_skill = data.complete_skill;
        this.survey_completion_date = data.survey_completion_date;

        const owner = grantsList.includes('owner');
        const admin = owner || grantsList.includes('admin');

        this.grants = {
          owner,
          admin,
          billing: admin || grantsList.includes('billing'),
          developer: admin || grantsList.includes('developer'),
          sales: admin || grantsList.includes('sales'),
        };

        this.enable_orders = this.grants.owner || ((this.contratto_cliente ?? false) && (this.grants.billing || this.grants.sales));
      } catch (ex) {
        this.$reset();
        throw ex;
      }
    },
  },

  getters: {
    compiledSurvey(state) {
      if (!state.is_reseller) {
        return true;
      }
      if (state.survey_completion_date === null) {
        return false;
      }
      const now = DateTime.local();
      return now.diff(parseDateTime(state.survey_completion_date), ['years']).years < 1;
    },
  },
});
