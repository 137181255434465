<template>
  <div class="row">
    <input v-if="name" v-model="fullDomain" :name="name" type="hidden">

    <x-input
      v-bind="$attrs"
      :model-value="modelValue"
      class="col-md-6 col-lg-4"
      :custom-validity="validity"
      :append="implicitTld"
      :placeholder="placeholder"
      :required="required"
      @update:model-value="$emit('update:modelValue', $event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import XInput from '../components/x-input.vue';
import { $t } from '@/i18n';

export default defineComponent({
  name: 'InputDomain',

  components: {
    XInput,
  },

  props: {
    name: String,
    placeholder: String,
    modelValue: {
      type: String,
      required: true,
    },
    tld: String,
    required: Boolean,
  },

  emits: ['update:modelValue'],

  computed: {
    fullDomain() {
      if (!this.modelValue) {
        return this.modelValue;
      }
      return `${this.modelValue}${this.implicitTld}`;
    },

    implicitTld() {
      if (!this.tld) {
        return '';
      }
      const suffix = `.${this.tld}`;
      return this.modelValue.substring(this.modelValue.length - suffix.length) !== suffix ? suffix : '';
    },

    validity() {
      let value = this.modelValue;
      if (value.substring(0, 4) === 'www.') {
        value = value.substring(4);
      }
      let testRegex;
      if (this.tld) {
        const suffix = `.${this.tld}`;
        if (value.substring(value.length - suffix.length) === suffix) {
          value = value.substring(0, value.length - suffix.length);
        }
        testRegex = /^[a-zàâäèéêëìîïòôöùûüæœçÿβ0-9]([\-a-zàâäèéêëìîïòôöùûüæœçÿβ0-9]*[a-zàâäèéêëìîïòôöùûüæœçÿβ0-9])?$/;
      } else {
        testRegex =
          /^([a-zàâäèéêëìîïòôöùûüæœçÿβ0-9]([\-a-zàâäèéêëìîïòôöùûüæœçÿβ0-9]*[a-zàâäèéêëìîïòôöùûüæœçÿβ0-9])?\.)+[a-zàâäèéêëìîïòôöùûüæœçÿβ0-9]+$/;
      }
      return testRegex.test(value) ? '' : $t('Non è un nome di dominio internet valido');
    },
  },
});
</script>
