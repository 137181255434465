<template>
  <page :name="$t('Profilo')" hide-breadcrumbs :loading="asyncComputed.anagrafica.updating">
    <div v-if="$route.query.pwr === '0'" class="alert alert-warning">
      <triangle-exclamation-icon />
      {{ $t('Reimpostare una nuova password dal form di modifica sottostante.') }}
    </div>

    <form v-if="!asyncComputed.anagrafica.updating" role="form" @submit.prevent="submit">
      <fieldset>
        <div class="row">
          <div class="form-group col-md-6 col-lg-4">
            <label for="anagraficaNome">{{ $t('Nome') }}</label>
            <input
              id="anagraficaNome"
              v-model="anagrafica.firstname"
              name="firstname"
              type="text"
              class="form-control"
              maxlength="250"
              autocomplete="firstname"
            >
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <label for="anagraficaCognome">{{ $t('Cognome') }}</label>
            <input
              id="anagraficaCognome"
              v-model="anagrafica.lastname"
              name="lastname"
              type="text"
              class="form-control"
              maxlength="250"
              autocomplete="lastname"
            >
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 col-lg-4">
            <label for="anagraficaTelefono">{{ $t('Telefono') }}</label>
            <input id="anagraficaTelefono" v-model="anagrafica.phone" type="tel" class="form-control" maxlength="20">
          </div>

          <div class="form-grou col-md-6 col-lg-4">
            <label for="anagraficaMobile">{{ $t('Cellulare') }}</label>
            <input
              id="anagraficaMobile"
              v-model="anagrafica.mobile"
              name="mobile"
              type="tel"
              class="form-control"
              maxlength="20"
              autocomplete="tel"
            >
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 col-lg-4">
            <label for="anagraficaEmail">{{ $t('Email') }} *</label>
            <input
              id="anagraficaEmail"
              v-model="anagrafica.mail"
              name="mail"
              type="email"
              class="form-control"
              required
              maxlength="100"
              autocomplete="email"
            >
            <div
              v-if="originalMail !== anagrafica.mail && login.username === originalMail"
              class="alert alert-info"
              role="alert"
            >
              <small>
                <strong>{{ $t('IMPORTANTE') }}</strong>:
                {{
                  $t(
                    "il nuovo indirizzo email verrà usato anche come username per accedere all'area riservata, al posto di {0}",
                    [login.username],
                  )
                }}.
              </small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 col-lg-4">
            <template v-if="login.grants.admin">
              <label>{{ $t('Notifiche Email') }}</label>
            </template>
            <template v-else>
              <label>{{ $t('Notifiche Email a {0}', [login.azienda]) }}</label>
              <div class="alert alert-info" role="alert">
                <small>
                  {{ $t('Puoi solamente rimuovere eventuali notifiche attive.') }}
                  <br>
                  {{ $t("L'aggiunta deve essere richiesta direttamente a {0}.", [login.azienda]) }}
                </small>
              </div>
            </template>
            <p>
              <toggle
                v-model="anagrafica.email_subscriptions"
                :disabled="!login.grants.admin && !originalSubscriptions.includes('technical')"
                on-value="technical"
              >
                {{ $t('Tecniche') }}
              </toggle>
              &nbsp;
              <form-help-tooltip
                :text="$t('Riceve in copia le notifiche tecniche (es. tickets, notifiche di sistema)')"
              />
            </p>
            <p>
              <toggle
                v-model="anagrafica.email_subscriptions"
                :disabled="!login.grants.admin && !originalSubscriptions.includes('billing')"
                on-value="billing"
              >
                {{ $t('Amministrative') }}
              </toggle>
              &nbsp;
              <form-help-tooltip
                :text="$t('Riceve in copia le notifiche amministrative (es. fatture, scadenze)')"
              />
            </p>
            <p>
              <toggle
                v-model="anagrafica.email_subscriptions"
                :disabled="!login.grants.admin && !originalSubscriptions.includes('marketing')"
                on-value="marketing"
              >
                {{ $t('Commerciali') }}
              </toggle>
              &nbsp;
              <form-help-tooltip
                :text="$t('Riceve in copia le notifiche commerciali (es. offerte dedicate)')"
              />
            </p>
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <label>{{ $t('Newsletter') }}</label>
            <div>
              <toggle v-model="anagrafica.newsletter_subscription" :on-value="true" :off-value="false">
                {{ $t('Iscritto alla nostra newsletter') }}
              </toggle>
            </div>
          </div>
        </div>

        <div class="pull-right">
          <em>* {{ $t('Campo obbligatorio') }}</em>
        </div>
        <div class="input-group-btn">
          <button class="btn btn-primary" type="submit" :disabled="sending" title="Aggiorna i tuoi dati">
            <span v-if="sending" class="spinner spinner-inline spinner-sm" />
            {{ $t('Aggiorna i tuoi dati') }}
          </button>
        </div>
      </fieldset>
    </form>

    <hr>
    <h2>{{ $t('Sicurezza') }}</h2>

    <form v-show="!asyncComputed.anagrafica.updating" role="form" @submit.prevent="changePassword">
      <fieldset :disabled="!anagrafica.id">
        <h4>{{ $t('Cambia Password') }}</h4>

        <div v-if="login.username !== originalMail" class="row">
          <div class="form-group col-md-6">
            <label>{{ $t('Username') }}</label>
            <input :value="login.username" readonly class="form-control">
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 col-lg-4">
            <label for="anagraficaResetPassword">{{ $t('Password') }}</label>
            <input
              id="anagraficaResetPassword"
              v-model="password"
              type="password"
              class="form-control"
              minlength="8"
              required
              autocomplete="new-password"
            >
          </div>

          <div class="form-group col-md-6 col-lg-4">
            <label for="anagraficaConfirmPassword">{{ $t('Conferma Password') }}</label>
            <input
              id="anagraficaConfirmPassword"
              v-model="confirmPassword"
              type="password"
              class="form-control"
              minlength="8"
              required
              autocomplete="off"
            >
          </div>
        </div>

        <button type="submit" class="btn btn-primary" :disabled="!password || password != confirmPassword || sending">
          {{ $t('Cambia Password') }}
        </button>
      </fieldset>
    </form>

    <h4>{{ $t('Autenticazione a due fattori') }}</h4>

    <toggle :model-value="anagrafica.twofa" @update:model-value="setTwoFA">
      {{ $t("Imposta l'account per ricevere codici di autenticazione tramite un'applicazione dedicata") }}
    </toggle>

    <twofa-enable-modal v-if="showEnableTwoFA" v-model:show="showEnableTwoFA" v-model:enabled="anagrafica.twofa" />
    <twofa-disable-modal v-if="showDisableTwoFA" v-model:show="showDisableTwoFA" v-model:enabled="anagrafica.twofa" />
  </page>
</template>

<style scoped>
hr {
  margin-top: 3em;
}
</style>

<script lang="ts">
import Page from '../components/page.vue';
import Toggle from '../components/toggle.vue';
import TwofaEnableModal from '../components/twofa-enable-modal.vue';
import TwofaDisableModal from '../components/twofa-disable-modal.vue';
import { setupAsyncComputed } from '@common/asyncComputed';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import TriangleExclamationIcon from '@vue-patternfly/icons/triangle-exclamation-icon';
import { useLoginStore } from '@/store/login';
import { Resource, type Profile, ProfilesResource } from '@/resources';
import { defineComponent } from 'vue';
import { useAppStore } from '@/store/app';
import { $t } from '@/i18n';

export default defineComponent({
  name: 'ProfilePage',

  components: {
    Page,
    Toggle,
    TwofaEnableModal,
    TwofaDisableModal,
    FormHelpTooltip,
    TriangleExclamationIcon,
  },

  setup() {
    const login = useLoginStore();
    return {
      login,
      ...setupAsyncComputed({
        anagrafica: {
          async get(this: any) {
            const anagrafica = await new ProfilesResource().get(this.login.id);
            this.originalMail = anagrafica.mail;
            this.originalSubscriptions = [...anagrafica.email_subscriptions];
            return anagrafica;
          },
          default: {
            id: 0,
            title: null,
            firstname: null,
            lastname: null,
            street: null,
            postalcode: null,
            city: null,
            province: null,
            country: null,
            phone: null,
            mobile: null,
            mail: '',
            newsletter_subscription: false,
            email_subscriptions: [],
            grants: [],
            twofa: false,
          } as Profile,
        },
      }),
    };
  },

  data() {
    return {
      originalMail: '',
      originalSubscriptions: [] as string[],
      password: '',
      confirmPassword: '',
      sending: false,
      showEnableTwoFA: false,
      showDisableTwoFA: false,
      verifyTOTP: '',
    };
  },

  methods: {
    async submit() {
      if (!this.anagrafica.id) {
        return;
      }

      // se non sono owner posso solo rimuovere le notifiche
      if (!this.login.grants.owner) {
        this.anagrafica.email_subscriptions = this.anagrafica.email_subscriptions.filter((type: string) =>
          this.originalSubscriptions.includes(type),
        );
      }

      const app = useAppStore();
      this.sending = true;
      try {
        await new ProfilesResource().put(this.anagrafica.id, this.anagrafica);
        app.toast($t('Profilo aggiornato correttamente.'), 'success');
      } catch {
        app.toast($t('Errore: profilo non aggiornato. Contatta il nostro supporto per maggiori informazioni.'), 'danger');
      } finally {
        this.sending = false;
      }

      this.asyncComputed.anagrafica.update();
      await this.login.refresh();
    },

    async changePassword() {
      const app = useAppStore();
      this.sending = true;
      try {
        await new Resource('login').put(this.anagrafica.id, {
          password: this.password,
        });
        app.toast($t('Password modificata correttamente.'), 'success');
      } catch {
        app.toast($t('Errore: password non modificata. Contatta il nostro supporto per maggiori informazioni.'), 'danger');
      } finally {
        this.sending = false;
      }
      this.password = '';
      this.confirmPassword = '';
    },

    async setTwoFA(enable: boolean) {
      if (enable) {
        this.showEnableTwoFA = true;
      } else {
        this.showDisableTwoFA = true;
      }
    },
  },
});
</script>
